.cikk {
	font-weight: 500;

	h1, h2 {
		color: $brand-primary;
	}

	a {
		font-weight: bold;
	}

	h3 {
		color: $text-color;
	}

	h4, h5, h6 {
		color: $text-color;
		font-size: 1.25em;
	}

	table {
		@extend .table;
		@extend .table-striped;

		width: 100% !important;
		border: 1px solid $table-border-color !important;

		caption {
			font-style: italic;
			text-align: center;
		}
	}

	img {
		max-width: 100%;
		display: block;
		height: auto !important;
		width: auto !important;
	}

	img[style*="float:left"],
	img[style*="float: left"] {
		margin-right: .5em;
	}

	img[style*="float:right"],
	img[style*="float: right"] {
		margin-left: .5em;
	}

	figure {
		background: transparentize($brand-primary, .8);
		padding: 10px;
		margin-bottom: 1em;

		figcaption {
			padding: 10px 0 0;
		}
	}
}