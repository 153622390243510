@mixin default-transition {
	@include transition(all .25s ease-in-out);
	@include transform-origin(50% 50%);
}

@include text-emphasis-variant('.text-white', #fff);
@include text-emphasis-variant('.text-info', $brand-info);

html, body {
	min-height: 100%;
}

.header-hero, .filharmonia-logo {
display: none;
}

body {
}

a, .btn {
	@include default-transition;
}

a.inverz {
	color: $text-color;

	&:hover,
	&:focus {
		color: $link-color;
	}
}

.btn {
	border-width: 0;
	text-transform: lowercase;
	font-weight: bold;

	&-notransform {
		text-transform: none;
	}
}

.footer {
	background: $sotet-hatter;
	padding: 20px 80px;
	margin-top: 80px;

	&-logo {
		margin-right: 80px;
	}

	&-copyright {
		color: #5e5e5e;
	}
}

.page-title {
	font-size: 40px;
}

.karmester-lista {
	margin: 50px $grid-gutter-width / -2 0;
}

.karmester-listaelem {
	padding: $grid-gutter-width / 2;
	text-align: center;

	&-kep {
		width: 100%;
	}

	&-txt {
		height: 24px * 3;
		margin: 5px 0 0;
	}

	&-nev {
		font-size: 20px;
		line-height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	&-orszag {
		font-size: 16px;
		margin: 0;
	}

	&-btns {
		margin: 0 -5px;
	}

	&-btn {
		padding: 0;
		margin: 5px;
		flex-basis: 50%;
		flex-grow: 1;
		background: #d49b37;
		font-size: 20px;
		padding: $padding-base-vertical 0;
	}
}

.karmester {
	> .flex {
		margin: 0 $grid-gutter-width / -2;

		> * {
			padding: $grid-gutter-width / 2;
		}
	}

	&-szavazas-btn {
		background: #d49b37;
		font-size: 22px;
		margin-top: 50px;
		padding: 0 1em;
	}

	.embed-responsive {
		width: 100%;
	}

	.karmester-listaelem-btn {
		padding-left: $padding-base-horizontal * 2;
		padding-right: $padding-base-horizontal * 2;
	}
}

.modal-header {
	background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.3 694.5'%3E%3ClinearGradient id='header-hero-border' gradientUnits='userSpaceOnUse' x1='-88.793' y1='314.046' x2='106.731' y2='370.486'%3E%3Cstop offset='0' stop-color='%23CE9330'/%3E%3Cstop offset='.099' stop-color='%23D49C37'/%3E%3Cstop offset='.269' stop-color='%23E4B54F'/%3E%3Cstop offset='.486' stop-color='%23FFDD7A'/%3E%3Cstop offset='.5' stop-color='%23FFE07D'/%3E%3Cstop offset='.675' stop-color='%23EEC35F'/%3E%3Cstop offset='1' stop-color='%23CE9330'/%3E%3C/linearGradient%3E%3Cpath fill='url%28%23header-hero-border%29' d='M.1 0h52.2v694.5H.1z'/%3E%3C/svg%3E");
	background-color: #f4f4f4;
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: $grid-gutter-width / 2 auto;
	padding-left: $grid-gutter-width;
	border-bottom: 0;
	box-shadow: 0 0 100px rgba(0, 0, 0, .05), inset 0 0 50px rgba(0, 0, 0, .08);

	.close {
		font-size: 40px;
	}
}

.modal-title {
	text-transform: uppercase;
	font-size: 28px;
}

.form-control {
	border-width: 2px;
	border-color: desaturate($brand-primary, 20%);
}

.btn-facebook-reg {
	background: #3b5999;
	text-transform: none;

	&:hover, &:focus {
		background: darken(#3b5999, 15%);
	}

	>span {
		font-size: 20px;
	}

	.ikon-facebook {
		width: 28px;
		height: 28px;
		display: inline-block;
		vertical-align: bottom;

		path {
			fill: #fff;
		}
	}
}

#szavazas {
	.grecaptcha-badge {
		display: none;
	}
}