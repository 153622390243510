.en {
		background: $sotet-hatter url($hatterkep-header-angol) 50% 0 no-repeat;

	}
	
.hu {
		background: $sotet-hatter url($hatterkep-header) 50% 0 no-repeat;

	}

.header {
	padding: 2rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background-size: cover;
	color: #fff;
	height: 330px;
	position: relative;
	

	.fooldal & {
		background-image: none;
		background-color: transparent;
		height: auto;

		&-hero {
			font-size: 50px;
		}
	}

	.aloldal & {
		margin-bottom: 50px;

		&-hero {
			position: absolute;
			left: auto;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}
	}

	&-nav {
		text-transform: uppercase;
		margin-bottom: 60px;
		line-height: 1;
		z-index: 5;
	}

	&-fomenu {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-right: 22px;
	}

	&-fomenupont {
		margin: 0 15px;
	}

	&-fomenupont-link:hover,
	&-fomenupont-link:focus {
		color: #fff;
	}

	&-lang-select {
		list-style: none;
		padding: 0;
		margin: 0;

		li+li {
			border-left: 2px solid $brand-primary;
			padding-left: 7px;
			margin-left: 7px;
		}

		.active>a {
			color: #fff;
		}

		a:hover, a:focus {
			color: #fff;
		}
	}

	&-hero {
		.bg{
			background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52.3 694.5'%3E%3ClinearGradient id='header-hero-border' gradientUnits='userSpaceOnUse' x1='-88.793' y1='314.046' x2='106.731' y2='370.486'%3E%3Cstop offset='0' stop-color='%23CE9330'/%3E%3Cstop offset='.099' stop-color='%23D49C37'/%3E%3Cstop offset='.269' stop-color='%23E4B54F'/%3E%3Cstop offset='.486' stop-color='%23FFDD7A'/%3E%3Cstop offset='.5' stop-color='%23FFE07D'/%3E%3Cstop offset='.675' stop-color='%23EEC35F'/%3E%3Cstop offset='1' stop-color='%23CE9330'/%3E%3C/linearGradient%3E%3Cpath fill='url%28%23header-hero-border%29' d='M.1 0h52.2v694.5H.1z'/%3E%3C/svg%3E");
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: contain;
			// margin-left: -4rem;
			padding-left: 1.8em;
		}
		align-self: flex-start;
		font-size: 30px;
		width: 17em;
		max-width: 100vw;

		&-title {
			font-size: 1.12em;
			margin: 0;
		}

		&-title-big {
			display: block;
			font-size: 4em;
			line-height: .75;
			text-indent: -0.075em;
			letter-spacing: -0.075em;
			margin-bottom: 20px;
		}

		&-ev {
			font-weight: normal;
		}

		&-p {
			color: $brand-info;
			margin: 15px 0 0 0;
			letter-spacing: -2px;
		}
	}
}

.filharmonia-logo {
	margin: 0;
}