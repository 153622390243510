@media (max-width: $screen-md-max) {
	.filharmonia-logo {
		height: 90px;
	}

	.header-hero {
		font-size: 32px;
	}

	.fooldal-aside {
		font-size: 26px;
		margin-top: -2rem;
	}
	a.btn.fooldal-szavazok-felul{
		position: static;
		padding: 0 .8em .2em;
		max-width: 300px;
		font-size: 30px;
	}
}