@media (max-width: $grid-float-breakpoint-max) {
	.header {
		padding: 4vmin;

		.aloldal & {
			height: 60vmin;

			&-hero {
				font-size: 3vmin;
				margin-top: 0;
				top: 20vmin;
				transform: none;
			}
		}

		&-hero {
			margin-left: -4vmin;
			font-size: 6vmin;
			margin-top: 60vmin;

			.fooldal & {
				font-size: 6vmin;
			}
		}

		&-hero-p {
			line-height: 2;
			margin: 0;
		}

		&-nav-toggle {
			width: 6vmin;
			height: 6vmin;
			margin: 2vmin;
			position: relative;
			z-index: 100;
			overflow: hidden;

			.line-close,
			.line-open {
				stroke: #fff;
				stroke-width: 10px;
				@include default-transition;
			}

			.line-close {
				opacity: 0;
				transform: translateX(-100%);
			}

			.line-open {
				opacity: 1;
				transform: translateX(0);
			}

			&.open {
				.line-close {
					opacity: 1;
					transform: translateX(0);
				}

				.line-open {
					opacity: 0;
					transform: translateX(100%);
				}
			}
		}

		&-nav {
			position: fixed;
			right: 100vw;
			width: calc(100vw - 12vmin);
			top: 0;
			bottom: 0;
			overflow-y: auto;
			background: transparentize(#000, .1);
			z-index: 1000;
			padding: 1rem;
			margin: 0;

			@include default-transition;

			&.open {
				right: 18vmin;
				box-shadow: 0 0 30px rgba(255,255,255, .2), 0 0 20px rgba(0,0,0,.4);
			}
		}

		&-lang-select {
			margin-bottom: 1rem;

			li+li {
				padding-left: 1rem;
				margin-left: 1rem;
			}
		}

		&-fomenu {
			margin: 0;
			border-top: 1px solid transparentize($brand-primary, .7);
		}

		&-fomenupont {
			padding: 1rem 0;
			margin: 0;
		}
	}

	.filharmonia-logo {
		position: absolute;
		top: 4vmin;
		left: 4vmin;
		height: auto;

		>a {
			display: block;
		}

		&-img {
			display: block;
			width: auto;
			height: 10vmin;
		}
	}

	.fooldal-aside {
		font-size: 5vmin;
		margin: 0;
	}

	.fooldal-article-cim {
		font-size: 7vmin;
	}

	.footer-logo {
		margin: 1rem 0;
	}

	.btn-fooldal {
		font-size: 10vw;
	}
}