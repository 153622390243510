.fooldal {
	background: $sotet-hatter url($hatterkep-header) 50% 0 no-repeat;
	background-size: 100vw auto;
	color: #fff;

	.modal {
		color: $text-color;
	}
}

.fooldal-aside {
	font-size: 30px;
	line-height: 1;
	margin-bottom: 9em;

	&-inner {
		background: #040d10;
		padding: 1.3em;
	}

	&-idopont {
		font-size: 3em;
		letter-spacing: -8px;
		margin-bottom: 10px;
		margin-top: -20px;
	}

	&-facebook {
		color: $brand-info;

		&:hover, &:focus {
			color: #fff;
		}

		.ikon-facebook {
			margin-right: 20px;
			@include default-transition;

			path {
				fill: #fff;
			}
		}
	}

	.ikon-naptar {
		margin-right: 10px;
	}
}

.btn-fooldal {
	@include button-variant($sotet-hatter, $brand-primary, $brand-primary);
	font-size: 4em;
	border-width: 4px;
	padding: 0 1.2em .2em;
	margin: 1em auto;

	&:hover,
	&:active,
	&:active:focus {
		background: transparent;
		color: darken($brand-primary, 20%);
		border-color: darken($brand-primary, 20%);
	}
}

.fooldal-szavazok-felul{
	position: absolute;
	right:10px;
	top: 50%;
	padding: 0 .8em .2em;
	margin-left: 30px;
}
.aloldal header .fooldal-szavazok-felul{
	display: none;
}